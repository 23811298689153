import React, { FC } from 'react';

import {ReactComponent as LoadingIcon} from "../../images/icon-spinner.svg";

interface ILoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    isLoading: boolean;
    disabled?: boolean;
    classNames?: string;
}

export const LoadingButton: FC<ILoadingButtonProps> = (props: ILoadingButtonProps) => {
    const { text, isLoading, disabled, classNames, ...rest} = props;
    const btnClass = classNames ? classNames : `a-button a-button--lemon has-loader ${isLoading ? 'is-loading' : ''}`;

    return (
        <button {...rest}
            className={btnClass}
            disabled={isLoading || disabled}>
          <span className="a-button__text">
            {text}
          </span>
          <LoadingIcon className="icon a-button__spinner" />
        </button>
    )
}

export default LoadingButton;
