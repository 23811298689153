import React, {FC, useRef, useState} from 'react';
import {ReactComponent as LoadingIcon} from "../../images/icon-spinner.svg";

interface IActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    onClick: () => Promise<void>;
    icon: React.ReactNode;
    text?: string;
    withLoader?: boolean;
    loading?: boolean;
}

export const ActionButton: FC<IActionButtonProps> = (props: IActionButtonProps) => {
    const { className, onClick, icon, text, withLoader, loading, ...rest } = props;
    const [isLoading, setIsLoading] = useState(false);
    const isRendered = useRef(true);

    const handleClick = (): () => void => {
        if (withLoader && isRendered.current) {
            setIsLoading(true);
        }

        onClick().finally(() => {
            if (isRendered.current) {
                setIsLoading(false);
            }
        });

        // Avoid async updates when component is not mounted.
        return (): void => { isRendered.current = false };
    };

    const btnCls = `a-button a-button--transparent a-button--icon action-btn ${className ? className : ''}`

    return (
        <button {...rest}
                className={btnCls}
                onClick={handleClick}>
            {text && (
              <span className={`a-button__text`}>
                  {text}
              </span>
            )}
            {isLoading || loading ? <LoadingIcon style={{height: '1.5rem'}} /> : icon}
        </button>
    )
}

export default ActionButton;
