import { getApiURL } from './Environment';
import {
  StatusQueryResponse,
  StatusQueryRequest,
  ErrorResponse
} from '../custom-types/Request';
import {Err, Ok, Result} from "./Result";

export const parseJsonError = (res: Response): Promise<ErrorResponse> => {
  const errObj: ErrorResponse = {errors: [res.statusText]};

  return res.json().then((error) => {
    if (error && {}.propertyIsEnumerable.call(error, 'errors')) {
      errObj.errors = error.errors;
    }

    if (error && {}.propertyIsEnumerable.call(error, 'fields')) {
      errObj.fields = error.fields;
    }

    return Promise.reject(errObj);
  }).catch(() => {
    // Catch empty body / cannot be json parsed
    return Promise.reject(errObj);
  })
};

/**
 * API Request handler
 * @param url        - api endpoint
 * @param method     - http method
 * @param token      - User auth token
 * @param urlParams  - Url Query params
 */
const apiRequest = async (
  url: string,
  method: string,
  token?: string,
  // eslint-disable-next-line
): Promise<any> => {
  const myHeaders = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  if (token && token !== null) {
    myHeaders.set('Authorization', token);
  }

  const apiURL = getApiURL();
  const fullUrl = apiURL && !(url.startsWith('.') || url.startsWith('http')) ? `${apiURL}${url}` : url;

  const req = fetch(
    fullUrl,
    {
      method,
      headers: myHeaders,
    },
  );

  return req.then((res) => {
    if (!res.ok) {
      return parseJsonError(res);
    }

    return res.json();
  })
  .then((resData) => new Ok(resData))
  .catch((reason) => new Err(reason))
};

export const getStatusForDomain = (params: StatusQueryRequest): Promise<Result<StatusQueryResponse, ErrorResponse>> => {
  return apiRequest(`/v2/auto_dnssec/domain_status/${params.domain_name}`, "GET");
};
