const pad = (number: number): string => {
  if (number < 10) {
    return `0${number}`;
  }

  return `${number}`;
}

export const getDateString = (datetime: string): string => {
  const d = new Date(datetime);
  return d.getUTCFullYear() +
        '-' + pad(d.getUTCMonth() + 1) +
        '-' + pad(d.getUTCDate()) +
        ' ' + pad(d.getUTCHours()) +
        ':' + pad(d.getUTCMinutes()) +
        ':' + pad(d.getUTCSeconds());
}
