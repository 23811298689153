import React from 'react';
//import logo from './logo.svg';
import './App.scss';
import RootContainer from './routes/RootContainer';

function App(): JSX.Element {
  return (
    <RootContainer />
  );
}

export default App;
