import React, { FC, useState, useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../images/search.svg';
import { ReactComponent as InfoIcon } from "../images/info.svg";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import { ReactComponent as ReadIcon } from "../images/read.svg";
import LoadingButton from './Buttons/LoadingButton'
import ActionButton from './Buttons/ActionButton'
import { StatusQueryResponse, StatusQueryRequest } from '../custom-types/Request';
import { TLD } from '../custom-types/TLD';
import { getStatusForDomain } from '../utils/Requests';
import { getTranslatedText, readableStatusText } from "../utils/Translations";
import { getDateString } from "../utils/DateTime";
import { toASCII } from 'punycode';

interface IResult extends StatusQueryResponse {
  domain: string;
}

interface IQueryProps {
  placeholderText: string;
}

interface IResultList {
  label: string;
  explanation?: string;
  value: string;
}

export const Query: FC<IQueryProps> = (props: IQueryProps) => {
  const { placeholderText } = props;
  const [queryResult, setQueryResult] = useState<IResult>();
  const [querying, setQuerying] = useState(false);
  const [queryError, setQueryError] = useState('');
  const [queryVal, setQueryVal] = useState('');

  const submitBtnClass = `a-button a-button--secondary o-search__submit ${querying ? 'is-loading' : ''}`;
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [resultList, setResultList] = useState<IResultList[]>([]);
  const [showHelpText, setShowHelpText] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [showAsJSON, setShowAsJSON] = useState(false);

  useEffect(() => {
    const hasTLD = queryVal.endsWith(`.${TLD.SE}`) || queryVal.endsWith(`.${TLD.NU}`);
    setSubmitBtnDisabled(!hasTLD || !queryVal);
  }, [queryVal])

  useEffect(() => {
    if (!queryResult?.status) {
      setResultList([]);
      return;
    }

    const results: IResultList[] = [
      {
        label: 'Domain',
        explanation: 'Queried domain.',
        value: queryResult.domain
      },
      {
        label: 'Status',
        explanation: 'Current status in the scanning process for the domain.',
        value: queryResult.status
      },
      {
        label: 'FirstValidAt',
        explanation: 'When the domain first passed validation. Not available if "FailedAt" is set.',
        value: queryResult.first_valid_at ? getDateString(queryResult.first_valid_at) : '',
      },
      {
        label: 'LastValidAt',
        explanation: 'When the domain last passed validation. Not available if "FailedAt" is set.',
        value: queryResult.last_valid_at ? getDateString(queryResult.last_valid_at) : '',
      },
      {
        label: 'FailedAt',
        explanation: 'When the domain failed validation in the scanning process.',
        value: queryResult.failed_at ? getDateString(queryResult.failed_at) : '',
      },
      {
        label: 'FailureReason',
        value: queryResult.failure_reason,
      },
    ];

    setResultList(results)
  }, [queryResult])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setQuerying(true);
    setQueryError('');

    const params: StatusQueryRequest = {
      domain_name: toASCII(queryVal)
    };

    getStatusForDomain(params).then((resp) => {
      resp.match({
        Ok: (okData) => {
          if (!okData.value.logs) {
            okData.value.logs = []
          }

          setQueryResult({...okData.value, domain: queryVal})
        },
        Err: (errData) => {
          setQueryError(errData.err.errors.toString())
        }
      })
    }).catch(() => {
      setQueryError('Could not get status (Error code 101). Please try again later.');
    }).finally(() => {
      setQuerying(false);
    });
  };

  const formatRegion = (val: string): string => {
    if (val.includes('eu-')) return 'Europe';
    if (val.includes('us-')) return 'North America';
    if (val.includes('ap-')) return 'Asia';
  
    return val;
  }

  const logsTable = (
    <div className="u-m-t-4">
      {queryResult && queryResult?.logs.length ? 
        <table id="log-list" className="m-table m-table--rows">
          <thead>
              <tr>
                  <th>{getTranslatedText('Message')}</th>
                  <th>{getTranslatedText('Region')}</th>
                  <th>{getTranslatedText('Timestamp')}</th>
              </tr>
          </thead>
          <tbody>
            {queryResult.logs.map((log, index) => (
              <tr key={index}>
                <td>{log.message}</td>
                <td>{formatRegion(log.region)}</td>
                <td>{log.timestamp ? getDateString(log.timestamp) : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
        :
        <div>No logs available</div>
      }
    </div>
  )

  const jsonResultBox = (): React.ReactNode => {
    const resultOb = {...queryResult}
    delete resultOb['logs'];
    const jsonb = JSON.stringify(resultOb, null, 2)

    return (
      <div className="json-box">
        <pre><code id="json-result">{jsonb}</code></pre>
      </div>
    )
  }

  const showInfo = () => {
    setShowHelpText(!showHelpText);
    setShowAsJSON(false);
  }

  const searchResults = (
    <div className="row">
      <div className="grid">
        {!querying && !queryError && queryResult?.status && (
          <div className="table-container">

            <div className="toolbar-actions">
              <ActionButton id="show-logs-btn"
                            className={`${showLogs ? 'active' : ''}`}
                            onClick={(): Promise<void> => Promise.resolve(setShowLogs(!showLogs))}
                            icon={showLogs ? <CloseIcon /> : <ReadIcon />}
                            disabled={!queryResult || queryResult?.logs.length < 1}
                            text="Logs" />
              
              <ActionButton id="show-help-btn"
                            className={`${showHelpText ? 'active' : ''}`}
                            onClick={(): Promise<void> => Promise.resolve(showInfo())}
                            icon={<InfoIcon />} />
              <button id="show-json-btn"
                      className={`a-button a-button--transparent a-button--icon action-btn ${showAsJSON ? 'active' : ''}`} 
                      onClick={() => {setShowAsJSON(!showAsJSON); setShowHelpText(false)}}>
                        <span className={`a-button__text`}>JSON</span>
              </button>
            </div>

            {!showAsJSON && (
              <table id="result-list" className="m-table m-table--lines table-with-help">
                <tbody>
                  {resultList.map((r, index) => {
                  if (!r.value) return;

                  return (
                    <tr key={index}>
                      {showHelpText && (
                        <td>
                          <span
                            style={{margin: 0}}
                            className={`text-with-help`}
                            role="tooltip">
                              {r.explanation && (
                                <span className="help-text">{r.explanation}</span>
                              )}
                          </span>
                        </td>
                      )}
                      <td>{r.label}</td>
                      <td>
                        {readableStatusText(r.value)}
                        {r.label.toLowerCase() === 'failurereason' && queryResult && queryResult?.logs.length && (
                          <button id="failure-see-more" 
                                  className="u-link a-button--transparent logs-link" 
                                  onClick={() => setShowLogs(true)}>
                          See more
                        </button>
                        )}
                      </td>
                    </tr>
                  )
                  })}
                </tbody>
              </table>
            )}
            {showAsJSON && jsonResultBox()}
            {showLogs && logsTable}
          </div>
        )}

        {!querying && queryError && (
          <div role="alert" className="m-alert m-alert--error u-m-b-0">
            <h2 className="u-m-b-1">An error occurred</h2>
            <p>{queryError}</p>
          </div>
        )}
      </div>
    </div>
  )

  const searchPadding = !queryError && !queryResult?.status ? "u-p-y-0" : "u-p-t-0 u-p-b-2";

  return (
   <div className="o-search o-search--tight">
    <div className={`o-search__padded ${searchPadding}`}>
      <form onSubmit={(e): void => handleSubmit(e)} noValidate>
        <div className="row display-flex align-items-center">

          <div className="grid-auto u-p-r-0">
            <span className="o-search__icon">
              <SearchIcon className="icon" />
            </span>
          </div>

          <div className="grid u-p-l-0 display-flex field-group">
            <label htmlFor="query-domain" className="u-visuallyhidden">={placeholderText}</label>
            <input
              autoComplete="off"
              autoFocus={true}
              type="url"
              className="o-search__input o-search__input--force-focus-look"
              placeholder={placeholderText}
              id="query-domain"
              name="query-domain"
              value={queryVal}
              onChange={(e): void => setQueryVal(e.target.value.trim())}
              aria-describedby="query-domain-help" />
            <LoadingButton
              text="Search"
              type="submit"
              disabled={submitBtnDisabled}
              isLoading={querying}
              classNames={submitBtnClass} />
          </div>
        </div>
      </form>
    </div>

    {showHelpText}
     {searchResults}
   </div>
  );
};

export default Query;
