import React, {FC} from 'react';
import Card from "../components/Card";
import Query from "../components/Query";

interface ISectionProps {
	children: React.ReactNode;
	className?: string;
}

const Section: FC<ISectionProps> = (props: ISectionProps) => {
	const { children, className } = props;
	const sectionClass = className ? className : '';
	return (
		<section className={`o-section ${sectionClass}`}>
			<div className="wrapper o-section__content">
				{children}
			</div>
		</section>
	)
};

const Landing: FC = () => {
  return (
    <main role="main" className="u-p-b-2">
			<Section className="u-p-y-0">
				<div className="article">
					<h1 className="supersize u-m-t-4">Automated DNSSEC</h1>
					<div className="article__content article__content--full-width">
						{/* eslint-disable max-len */}
						<p className="preamble width-full">
							Managing keys to sign your domain with DNSSEC has previously been quite difficult. The functionality now implemented in the .se and .nu zones makes that process automatic and much easier for the user.
							<br />
							You can read more about this functionality
							<a id="more-info" href="https://internetstiftelsen.se/dns-labs/tech/easier-handling-of-records-with-automated-dnssec-provisioning/" title="Automated DNSSEC provisioning">here</a>.
						</p>
						<p className="preamble width-full">This status page shows the expected processing date for ongoing change requests and additional information for any error preventing a requested change from going forward.</p>
						{/* eslint-enable max-len */}
						
					</div>
				</div>
			</Section>

			<Section className="background-ocean-light u-p-t-3">
				<React.Fragment>
					<h2 className="alpha">Query domain</h2>
					<div className="row">
						<div className="grid-18 grid-md-12 justify-content-center">
							<p>
								{/* eslint-disable-next-line max-len */}
								Search for a domain name ending with either &quot;.se&quot; or &quot;.nu&quot; in the input field and hit search to see the status.<br />
								<span>Any dates presented are in UTC.</span>
							</p>
							<p>
								{/* eslint-disable-next-line max-len */}
								If any error occurred during the scanning process you can retrieve more information about the error by testing the domain name in our testing tool
								<a id="zonemaster" href="https://zonemaster.iis.se/" title="Zonemaster">Zonemaster</a>.
							</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="grid-18 grid-md-12">
							<Card>
								<Query placeholderText="Domain name"/>
							</Card>
						</div>
					</div>
				</React.Fragment>
			</Section>
    </main>
  );
};

export default Landing;
