import React, { FC, useEffect } from 'react';

/** Presentation */
import Landing from './Landing';
import TopNav from "../components/Navigation/TopNav";
import Footer from "../components/Navigation/Footer"

const RootContainer: FC = () => {

  useEffect(() => {
    document.body.addEventListener('keydown', (event) => {
      const keyCode = event.key;
      if (keyCode === 'Tab' ) {
        document.body.classList.add('onFocus');
      }
    });

    document.body.addEventListener('mousedown', () => {
      document.body.classList.remove('onFocus');
    });
  }, []);

  return (
    <div id="site" className="site">
      <TopNav />
      <div className="site__main">
        <div className="site__main__content">
          <Landing />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RootContainer;
